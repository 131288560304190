import React from "react";
import Layout from "../../components/Layout/Layout";
import Head from "../../components/Head/Head";
import { PageProps } from "gatsby";
import { PageContext } from "../../types";
import CTAForm from "../../components/CTAForm/CTAForm";

const CTAFormPage: React.FC<PageProps<void, PageContext>> = ({
  pageContext,
}) => {
  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head pageContext={pageContext}/>
      <CTAForm pageContext={pageContext}/>
    </Layout>
  )
}

export default CTAFormPage