import React from 'react'
import { useTranslate } from 'react-polyglot'
import Header from 'src/components/Header/Header'
import { LanguageCode, NavigationItem } from 'src/types'
import {
  getNavigationItems,
  getBusinessNavigationItems,
} from 'src/lib/navigation'
import { useLocale } from 'src/lib/I18nContext'
import { Box, Flex } from "@chatterbug/aaron";
import Logo from "../../../../components/Logo/Logo";

export type Props = {
  l2Code?: LanguageCode | null
  canonicalPath: string
}

const BusinessLogo: React.FC = () => {
  return (
    <Flex alignItems="center">
      <Logo iconOnly/>
      <Flex variant="text.h5" color="black0" ml="1x" fontSize={{_: '1.125rem'}}>
        chatterbug<Box color="gray60" ml=".5rem">for business</Box>
      </Flex>
    </Flex>
  )
}

const BusinessHeader: React.FC<Props> = ({ l2Code, canonicalPath }) => {
  const t = useTranslate()
  const locale = useLocale()
  const ITEMS: NavigationItem[] = getNavigationItems(t, locale).slice(1).filter(i => i?.name !== "business")

  return (
    <Header
      l2Code={l2Code}
      canonicalPath={canonicalPath}
      items={ITEMS}
      customLogo={<BusinessLogo/>}
      secondaryItemsGroup={{
        items: getBusinessNavigationItems(t),
      }}
    />
  )
}

export default BusinessHeader

export { BusinessLogo }