/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Helmet } from 'react-helmet'
import { PageContext, SiteLocale } from 'src/types'
import { getEnabledLocaleCodes } from 'src/config/i18n'

import schema from './schema.json'

const TWITTER_ACCOUNT = '@chatterbugapp'
const SITE_URL = process.env.SITE_URL || ''
const AVAILABLE_OG_IMAGES = ['en', 'de', 'es', 'fr']

const APPS = {
  main: {
    name: 'Chatterbug',
    ios: 1352963551,
    android: 'com.chatterbug.mobile',
  },
  streams: {
    name: 'Chatterbug Streams',
    ios: 1549226733,
    android: 'com.chatterbug.chatterstreams',
  },
}

type MetaItem = {
  name: string
  content: string
}

type Props = {
  title?: string
  titleOverride?: string
  pageContext: PageContext
  description?: string
  socialImage?: string
  meta?: MetaItem[]
  realDomainCanonicalUrl?: string
  appName?: keyof typeof APPS
  enabledLocaleCodes?: SiteLocale[]
  noIndex?: boolean
}

const Head: React.FC<Props> = ({
  title,
  titleOverride,
  pageContext,
  description = '',
  socialImage,
  meta = [],
  realDomainCanonicalUrl,
  appName,
  enabledLocaleCodes,
  noIndex,
}) => {
  const t = useTranslate()

  const titlePrefix = title ? `${title} | ` : ''
  const metaTitle = titleOverride || `${titlePrefix}${t('website.title')}`
  const metaDescription = description || t('website.description')
  const currentLocale = pageContext.locale
  const currentCanonicalPath = pageContext.canonicalPath
  const defaultSocialImageUrl = `${SITE_URL}/assets/global/og-images/org/${
    AVAILABLE_OG_IMAGES.includes(String(currentLocale))
      ? currentLocale
      : AVAILABLE_OG_IMAGES[0]
  }.jpg`
  const socialImageUrl = socialImage
    ? `${SITE_URL}${socialImage}`
    : defaultSocialImageUrl

  const mobileApp = (appName && APPS[appName]) || APPS.main

  return (
    <Helmet
      htmlAttributes={{
        lang: currentLocale,
      }}
      title={metaTitle}
      meta={[
        {
          name: 'robots',
          content: noIndex ? 'noindex, nofollow' : 'index, follow',
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:locale',
          content: currentLocale,
        },
        {
          property: 'og:image',
          content: socialImageUrl,
        },
        {
          property: 'og:image:width',
          content: 1200,
        },
        {
          property: 'og:image:height',
          content: 630,
        },
        {
          property: 'fb:app_id',
          content: process.env.FACEBOOK_APP_ID,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: TWITTER_ACCOUNT,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: socialImageUrl,
        },
        {
          name: 'twitter:app:name:iphone',
          content: mobileApp.name,
        },
        {
          name: 'twitter:app:id:iphone',
          content: mobileApp.ios,
        },
        {
          name: 'twitter:app:name:ipad',
          content: mobileApp.name,
        },
        {
          name: 'twitter:app:id:ipad',
          content: mobileApp.ios,
        },
        {
          name: 'twitter:app:name:googleplay',
          content: mobileApp.name,
        },
        {
          name: 'twitter:app:id:googleplay',
          content: mobileApp.android,
        },
        {
          property: 'al:ios:app_name',
          content: mobileApp.name,
        },
        {
          property: 'al:ios:app_store_id',
          content: mobileApp.ios,
        },
        {
          property: 'al:android:package',
          content: mobileApp.android,
        },
        {
          property: 'al:android:app_name',
          content: mobileApp.name,
        },
        {
          name: 'google-site-verification',
          content: process.env.GOOGLE_SITE_VERIFICATION,
        },
        {
          name: 'facebook-domain-verification',
          content: process.env.FACEBOOK_DOMAIN_VERIFICATION,
        },
      ]
        .filter((item) => item.content)
        .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
      {realDomainCanonicalUrl && (
        <link
          rel="canonical"
          href={realDomainCanonicalUrl}
          hrefLang={currentLocale}
        />
      )}
      {!realDomainCanonicalUrl &&
        (enabledLocaleCodes || getEnabledLocaleCodes()).map(
          (locale: string) => {
            if (currentLocale === locale) {
              return null
            }

            return (
              <link
                rel="alternate"
                href={`${SITE_URL}/${locale}${currentCanonicalPath}`}
                hrefLang={locale}
                key={locale}
              />
            )
          }
        )}
      {!realDomainCanonicalUrl && (
        <link
          rel="alternate"
          href={`${SITE_URL}/en${currentCanonicalPath}`}
          hrefLang="x-default" // fallback for all unsupported languages
        />
      )}
    </Helmet>
  )
}

export default Head
