import React from 'react'
import { CSSProperties } from 'styled-components'
import { Box } from '@chatterbug/aaron'

import { PageAccentVariant } from 'src/types'

// png looks the same across all browsers, while svg does not
import blueBackground from './assets/blue.png'
import pinkBackground from './assets/pink.png'

export const BACKGROUND_CIRCLE_SIZE = 2265

export const makeCentered = () => ({
  left: '50%',
  marginLeft: -BACKGROUND_CIRCLE_SIZE / 2, // to center horizontally
})

export type Props = {
  sx?: CSSProperties
  variant: PageAccentVariant
}

const GradientBackground: React.FC<Props> = ({ sx, variant }) => {
  const background = React.useMemo(() => {
    if (variant === PageAccentVariant.gradient) {
      return {
        background: 'linear-gradient(130deg, rgba(109, 31, 255, 0.2) 0%, rgba(97, 211, 245, 0.2) 100%)',
        zIndex: '-1',
      }
    }
    return {
      background: `url(${variant === PageAccentVariant.blue ? blueBackground : pinkBackground})`
    }
  }, [variant])

  return (
    <Box
      data-testid="background"
      sx={{
        pointerEvents: 'none',
        backgroundRepeat: 'no-repeat',
        width: BACKGROUND_CIRCLE_SIZE,
        height: BACKGROUND_CIRCLE_SIZE,
        ...background,
        ...(sx || {}),
      }}
    />
  )
}

export default GradientBackground
