import React from "react";
import { PageAccentVariant, PageContext } from "../../types";
import PageContainer from "../PageContainer/PageContainer";
import { FormHeader } from "./Header";
import GradientBackground, { makeCentered } from "../GradientBackground/GradientBackground";
import { Box } from "@chatterbug/aaron";
import { withTheme } from "styled-components";
import { ElForm } from "./ElForm";
import { ElTrust } from "./ElTrust";

type IProps = {
  pageContext: PageContext,
  theme?: {
    breakpoints: {
      tablet: string
      tabletWide: string
      laptop: string
    }
  }
}

const CTAForm = ({ pageContext, theme }: IProps) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}
    >
      <PageContainer>
        <Box position="relative">
          <GradientBackground
            variant={PageAccentVariant.gradient}
            sx={{
              position: 'absolute',
              [`@media screen and (max-width: ${
                parseInt(String(theme?.breakpoints.tablet)) - 1
              }px)`]: {
                ...makeCentered(),
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tablet})`]: {
                ...makeCentered(),
              },
              [`@media screen and (min-width: ${theme?.breakpoints.tabletWide})`]: {
                ...makeCentered(),
              },
            }}
          />
          <Box sx={{ position: "relative" }}>
            <FormHeader
              canonicalPath={pageContext.canonicalPath}
            />
            <Box
              display="flex"
              pt="3.31rem"
              px={{
                _: "0",
                tabletWide: "25px",
              }}
              pb="5.81rem"
              alignItems={{
                _: "center",
                tabletWide: "flex-start"
              }}
              flexDirection={{
                _: "column",
                tabletWide: "row",
              }}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <ElForm/>
              <ElTrust/>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </Box>
  )
}
export default withTheme(CTAForm)