import { Box, Image } from "@chatterbug/aaron";
import React from "react";
import * as SelectPrimitive from '@radix-ui/react-select';
import errorImage from "./assets/error.svg"

const Label = ({ children, error, ...rest }: any) => {
  return (
    <Box
      as="label"
      px=".5rem"
      color={error ? 'red44' : 'gray46'}
      backgroundColor="white100"
      fontSize=".75rem"
      lineHeight="1.2rem"
      sx={{
        position: "absolute",
        right: "1.56rem",
        top: "0",
        zIndex: "30",
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

const Input = React.forwardRef<HTMLInputElement, any>(
  ({ label, error, ...rest }, ref) => {
    return (
      <Box
        pt=".75rem"
        pb="1.5rem"
        sx={{
          position: "relative"
        }}
      >
        <Label
          htmlFor={rest.name}
          error={error}
        >
          {label}
        </Label>
        <Box
          variant="text.paragraph"
          as="input"
          type="text"
          id={rest.name}
          py=".56rem"
          pl="1.4rem"
          pr="1.59rem"
          color="black"
          width="100%"
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: error ? 'red44' : 'gray80',
            borderRadius: '2.5rem',
            outline: "none",

            "&::placeholder": {
              color: error ? 'red44' : "gray46",
            }
          }}
          {...rest}
          ref={ref}
        />
        {error && (
          <>
            <Image src={errorImage} sx={{
              position: "absolute",
              right: "37px",
              top: "22px",
            }}/>
            <Box
              variant="text.paragraphSmall"
              color="red44"
              ml="24px"
              sx={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            >
              {error.message}
            </Box>
          </>
        )}
      </Box>
    )
  })

const Select = React.forwardRef<any, any>(
  ({ error, children, placeholder, label, ...props }: any, ref) => {
    return (
      <Box
        pt=".75rem"
        pb="1.5rem"
        sx={{
          position: "relative"
        }}
      >
        <Label
          htmlFor={props.name}
          error={error}
        >
          {label}
        </Label>
        {error && (
          <>
            <Image src={errorImage} sx={{
              position: "absolute",
              right: "37px",
              top: "22px",
            }}/>
            <Box
              variant="text.paragraphSmall"
              color="red44"
              ml="24px"
              sx={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            >
              {error.message}
            </Box>
          </>
        )}
        <SelectPrimitive.Root {...props} onValueChange={props.onChange} defaultValue={props.value}>
          <Box
            as={SelectPrimitive.Trigger}
            variant="text.paragraph"
            type="text"
            id={props.name}
            py=".56rem"
            pl="1.4rem"
            pr="1.59rem"
            width="100%"
            display="flex"
            color="black"
            sx={{
              backgroundColor: "transparent",
              justifyContent: "space-between",
              position: "relative",
              appearance: "none !important",
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: error ? 'red44' : 'gray80',
              borderRadius: '2.5rem',
              outline: "none",

              "&[data-placeholder]": {
                color: error ? 'red44' : "gray46",
              }
            }}
            ref={ref}
          >
            <Box
              as={SelectPrimitive.Value}
              placeholder={placeholder}
            />
          </Box>

          <SelectPrimitive.Portal>
            <Box
              as={SelectPrimitive.Content}
              sx={{
                zIndex: "50",
              }}
            >
              <Box
                as={SelectPrimitive.Viewport}
                py=".5rem"
                sx={{
                  overflow: "hidden",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  width: "100%",
                  boxShadow: "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
                }}
              >
                {children}
              </Box>
              <SelectPrimitive.ScrollDownButton/>
              <SelectPrimitive.Arrow/>
            </Box>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </Box>
    )
  }
)

const SelectItem = React.forwardRef<any, any>(
  ({ children, ...props }, ref) => {
    return (
      <Box
        as={SelectPrimitive.Item}
        ref={ref}
        variant="text.paragraph"
        {...props}
        px="1rem"
        sx={{
          cursor: "pointer",
          outline: "none",

          "&:hover": {
            backgroundColor: "gray95",
          }
        }}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      </Box>
    )
  }
)


const Textarea = React.forwardRef<HTMLTextAreaElement, any>(
  ({ label, error, ...rest }, ref) => {
    return (
      <Box
        pt=".75rem"
        sx={{
          position: "relative"
        }}
      >
        <Label
          htmlFor={rest.name}
          error={error}
        >
          {label}
        </Label>
        <Box
          variant="text.paragraph"
          as="textarea"
          id={rest.name}
          py=".56rem"
          pl="1.4rem"
          pr="1.59rem"
          width="100%"
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'gray80',
            borderRadius: '1rem',
            resize: "vertical",
            outline: "none",
            "&::placeholder": {
              color: "gray46",
            }
          }}
          {...rest}
          ref={ref}
        />
      </Box>
    )
  })

export { Input, Select, SelectItem, Textarea }