import React from "react";
import { BusinessLogo } from "src/sections/Business/subcomponents/BusinessHeader/BusinessHeader";
import { useLocale } from "../../lib/I18nContext";
import { getInitiallySelectedL2Code } from "../../lib/localization";
import { Box, Flex, Grid } from "@chatterbug/aaron";
import LocalizedLink from "../LocalizedLink/LocalizedLink";
import Navigation from "../Navigation/Navigation";
import DropdownSiteLocale from "../../sections/DropdownSiteLocale/DropdownSiteLocale";
import HamburgerMenu from "../Header/subcomponents/HamburgerMenu/HamburgerMenu";

const FormHeader = ({ canonicalPath }: { canonicalPath: string }) => {
  const locale = useLocale()
  const initialL2Code = getInitiallySelectedL2Code(locale)

  return (
    <Flex
      as="header"
      minHeight={68}
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth={1}
      borderBottomStyle="solid"
      borderBottomColor='gray60'
    >
      <Flex alignItems="center">
        <Box>
          <LocalizedLink to="/business">
            <BusinessLogo/>
          </LocalizedLink>
        </Box>
        <Box ml="4x" display={{ _: 'none', tabletWide: 'block' }}>
          <Navigation isBackgroundDark={false} items={[]}/>
        </Box>
      </Flex>
      <Grid
        columns={`repeat(2, auto)`}
        alignItems="center"
        display={{ _: 'none', tabletWide: 'grid' }}
      >
        <DropdownSiteLocale
          canonicalPath={canonicalPath}
          hideSelectedItemLabel
          buttonWidth={72}
          menuWidth={124}
        />
      </Grid>
      <HamburgerMenu
        l2Code={initialL2Code}
        canonicalPath={canonicalPath}
        isBackgroundDark={false}
        items={[]}
        secondaryItemsGroup={{ items: [] }}
        hideLogin
      />
    </Flex>
  )
}
export { FormHeader }