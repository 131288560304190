import { Box, Flex, Heading } from "@chatterbug/aaron";
import React, { useState } from "react";
import Button, { BUTTON_VARIANTS } from "../Button/Button";
import { Input, Select, SelectItem, Textarea } from "./FormElements";
import { useTranslate } from "react-polyglot";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SubmittedModal } from "./SubmittedModal";
import { useLocale } from "../../lib/I18nContext";

type Inputs = {
  name: string
  email: string
  participants: string
  comment: string
}

const ElForm = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      comment: "",
      email: "",
      participants: undefined,
    }
  })
  const locale = useLocale()

  const [submitted, setSubmitted] = useState(false)
  const onSubmit: SubmitHandler<Inputs> = (data) => fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams({
      ...data,
      "lang": locale,
      "form-name": "business-form",
    }).toString(),
  })
    .then(async (r) => {
      if (!r.ok) {
        throw new Error(await r.text())
      }
    })
    .then(() => setSubmitted(true))
    .then(() => reset())
    .catch(() => {
    })

  const t = useTranslate()
  return (
    <Box
      maxWidth="29.6rem"
    >
      <SubmittedModal open={submitted} setOpen={setSubmitted}/>

      <Box
        as="form"
        name="business-form"
        backgroundColor="white100"
        px="1.25rem"
        py="2.25rem"
        display="flex"
        flexDirection="column"
        sx={{
          gap: "1rem",
          borderRadius: "1rem",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 2px 4px 0px rgba(0, 0, 0, 0.03), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 16px 0px rgba(0, 0, 0, 0.03)",
        }}
        method="POST"
        data-netlify="true"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="lang"/>
        <Heading
          variant="h3"
          textAlign="center"
        >
          {t('Book a free demo')}
        </Heading>
        <Box
          variant="text.paragraph"
          textAlign="center"
          backgroundColor="blue90"
          p=".5rem"
          sx={{
            borderRadius: ".5rem",
          }}
        >
          {t("Speak to our language experts and learn how Chatterbug can benefit your team")}
        </Box>
        <Flex flexDirection="column">
          <Controller
            name="name"
            control={control}
            rules={{ required: t("This field is required") }}
            render={({ field }) => (
              <Input
                label={`${t("Name")}*`}
                placeholder={t("Petra Colins")}
                error={errors.name}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: t("This field is required") }}
            render={({ field }) => (
              <Input
                label={`${t("Work Email")}*`}
                placeholder={t("petra@company.com")}
                error={errors.email}
                {...field}
              />
            )}
          />

          <Controller
            name="participants"
            control={control}
            rules={{ required: t("This field is required") }}
            render={({ field }) => (
              <Select
                label={`${t("Estimated Number of Learners")}*`}
                placeholder="Please select"
                error={errors.participants}
                {...field}
              >
                <SelectItem value="just_me">{t("Just me")}</SelectItem>
                <SelectItem value="1_10">1-10</SelectItem>
                <SelectItem value="11_50">11-50</SelectItem>
                <SelectItem value="51_200">51-200</SelectItem>
                <SelectItem value="200_">200+</SelectItem>
                <SelectItem value="not_sure">{t("Not sure yet")}</SelectItem>
              </Select>
            )}
          />

          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <Textarea
                label={t("Questions or Comments?")}
                placeholder={t("Type here...")}
                {...field}
              />
            )}
          />
        </Flex>
        <Button
          variant={BUTTON_VARIANTS.primary}
          type="submit"
          width={{
            _: '100%',
          }}
          sx={{
            background: 'linear-gradient(336deg, #B592F7 0%, #61D3F5 100%), linear-gradient(337deg, #704AB7 0%, #3B71FB 100%)',
            color: 'white',
            border: "none",
          }}
          height={{ _: '44px' }}
          href={null}
          label={t("Request a demo")}
        />
      </Box>
      <Box
        variant="text.paragraph"
        mt="1.81rem"
        px="3rem"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t("By submitting this form you agree to Chatterbug’s Privacy Policy", {
            a: "<a href=\"https://chatterbug.com/en/legal/privacy\" target='_blank' style=\"color: inherit\">",
            a_end: "</a>"
          })
        }}
      />
    </Box>
  )
}

export { ElForm }
