import { Box, Flex, Heading, Image } from "@chatterbug/aaron";
import React, { ReactNode } from "react";
import Logo1 from "../../sections/Business/subcomponents/BusinessClients/assets/logo-1.png";
import Logo2 from "../../sections/Business/subcomponents/BusinessClients/assets/logo-2.png";
import Logo3 from "../../sections/Business/subcomponents/BusinessClients/assets/logo-3.png";
import Logo4 from "../../sections/Business/subcomponents/BusinessClients/assets/logo-4.png";
import sessionsImage from "./assets/sessions.svg"
import cardsImage from "./assets/cards.svg"
import { useTranslate } from "react-polyglot";

const Comp = ({ image, heading, text }: {
  image: string,
  heading: ReactNode,
  text: ReactNode
}) => (
  <Flex display="flex">
    <Image src={image} width="3.5rem" height="3.5rem"/>
    <Box ml="2rem">
      <Heading
        variant="h3"
      >
        {heading}
      </Heading>
      <Box
        variant="text.paragraph"
      >
        {text}
      </Box>
    </Box>
  </Flex>
)

const ElTrust = () => {
  const t = useTranslate()
  return (
    <Box
      maxWidth="622px"
      mt="2.25rem"
      px="1rem"
    >
      <Heading
        variant="h3"
        textAlign="center"
      >
        {t("Trusted by leading companies")}
      </Heading>
      <Flex
        overflowX={{ _: 'hidden', tabletWide: 'auto' }}
        flexWrap="wrap"
        mt="24px"
        sx={{ justifyContent: 'center' }}
        maxWidth="512px"
      >
        <Box sx={{ minWidth: '163px', maxWidth: '256px' }}>
          <Image src={Logo1}/>
        </Box>
        <Box sx={{ minWidth: '163px', maxWidth: '256px' }}>
          <Image src={Logo2}/>
        </Box>
        <Box sx={{ minWidth: '163px', maxWidth: '256px' }}>
          <Image src={Logo3}/>
        </Box>
        <Box sx={{ minWidth: '163px', maxWidth: '256px' }}>
          <Image src={Logo4}/>
        </Box>
      </Flex>
      <Flex
      >
        <Flex
          mx="auto"
          flexDirection="column"
          mt="3rem"
          sx={{
            gap: ".7rem"
          }}
        >
          <Comp
            image={sessionsImage}
            heading="175,000+"
            text={t("Live Lessons taken")}
          />
          <Comp
            image={cardsImage}
            heading="40M+"
            text={t("Flashcards solved")}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export { ElTrust }